import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { withStore } from "react-context-hook";
import "./App.scss";

import TagManager from "react-gtm-module";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const Quote = React.lazy(() => import("./views/Quote/Quote"));

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_HOST,
  cache: new InMemoryCache(),
});

const tagManagerArgs = {
  gtmId: "GTM-TF92RT4",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              path="/"
              name="Quote"
              render={(props) => <Quote {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </ApolloProvider>
  );
}

export default withStore(App);
